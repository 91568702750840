export default function LoadingCreator() {
  return (
    <>
      <div className="mt-4 w-full mx-auto">
          <div className="relative p-4 w-full bg-white rounded-lg overflow-hidden rounded-lg">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="mx-auto h-48 w-48 bg-gray-200 rounded-full"></div>
                <div className="space-y-4">
                  <div className="h-4 bg-gray-200 rounded mx-auto w-1/2"></div>
                  <div className="h-4 bg-gray-200 rounded mx-auto w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded mx-auto w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded mx-auto w-3/4"></div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}
