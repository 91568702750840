

export const getCreatorsNaturalLanguage = (
  query: String | null,
  filters: object,
  amt: number | null,
  idToken: string,
) => {
  if (amt === null) {
    amt = 50
  }
  return fetch(
    `${
      process.env.REACT_APP_API_ENDPOINT
    }/creators/natural-language?query=${query}&amt=${amt}&filters=${encodeURIComponent(
      JSON.stringify(filters)
    )}`,
    {
      mode: "cors",
      method: "GET",
      headers: { 'Authorization': idToken },
    }
  );
};

export const getCreator = (anchor: string | undefined, idToken:string) => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/creator/${anchor}`, {
      mode: "cors",
      method: "GET",
      headers: { 'Authorization': idToken },
    });
};
// type can be data or chart
// qParamKey can be 'keys_to_plot' or 'period'
// qParamValue can be ['tik_tok_followers', 'tik_tok_likes', ...] or amt of days ie 30,60,90
export const getCreatorTimeSeriesChart = (anchor: string | undefined,idToken: string)=>{
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/creator/${anchor}/time-series/chart`, {
    mode: "cors",
    method: "GET",
    headers: { 'Authorization': idToken },
  });
}
