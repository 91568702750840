import { getKeysWithHighestValue } from "../../../services/data-formatting.ts";

export default function TopContent(props: {
  creator: any;
  loading: boolean;
}) {
  return (
    <>
      {!props.loading && (
        <div className="grid grid-grid-cols-1 sm:flex sm:justify-evenly">
          <div className="mt-4 sm:w-1/3 mx-1 rounded-xl border border-gray-200 bg-gray-100 overflow-hidden">
            <div className="text-xl capitalize p-2">Top hashtags used</div>
            <div className="bg-white mt-2 h-48 overflow-y-scroll p-2">
              {getKeysWithHighestValue(
                props.creator.tik_tok_hashtags_used,
                100
              ).map((hashtag) => (
                <div className="flex justify-between" key={hashtag}>
                  <div>{hashtag}</div>
                  <div>{props.creator.tik_tok_hashtags_used[hashtag]}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4 sm:w-1/3 mx-1 rounded-xl border border-gray-200 bg-gray-100 overflow-hidden">
            <div className="text-xl capitalize p-2">Top users tagged</div>
            <div className="bg-white mt-2 h-48 overflow-y-scroll p-2">
              {getKeysWithHighestValue(
                props.creator.tik_tok_users_tagged,
                100
              ).map((tagged) => (
                <div className="flex justify-between" key={tagged}>
                  <div>{tagged}</div>
                  <div>{props.creator.tik_tok_users_tagged[tagged]}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4 sm:w-1/3 mx-1 rounded-xl border border-gray-200 bg-gray-100 overflow-hidden">
            <div className="text-xl capitalize p-2">Top keywords used</div>
            <div className="bg-white mt-2 h-48 overflow-y-scroll p-2">
              {getKeysWithHighestValue(
                props.creator.tik_tok_keywords_used,
                100
              ).map((keyword) => (
                <div className="flex justify-between" key={keyword}>
                  <div>{keyword}</div>
                  <div>{props.creator.tik_tok_keywords_used[keyword]}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
