export default function Footer() {
  return ( 
    <footer className="bg-white">
      <div className="mx-auto text-center w-full max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <p className="mt-48 text-center text-xs leading-5 text-gray-500">
          &copy; 2023 Softserve Software, LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
