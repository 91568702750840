import Brand from "../components/about/brand/Brand";
import Menu from "../components/about/menu/Menu";
import Tech from "../components/about/tech/Tech";
import { useParams } from "react-router-dom";
import HeaderLarge from "../components/header/headerLarge/HeaderLarge.tsx";
import Footer from "../components/footer";
import Agency from "../components/about/agency/./Agency";
import Creator from "../components/about/creator/Creator";

// about is a route that displays the about page and renders components dependant upon the route
// about - this should display buttons for creator, agency, brand, and tech
// about/:aboutType - this should conditionally render the corresponding aboutType component

export default function About() {
  let { aboutType } = useParams();

  return (
    <div className="h-screen">
      <HeaderLarge />
      <div className="mt-8">
        {aboutType === undefined ? <Menu /> : <></>}
        {aboutType === "creator" ? <Creator /> : <></>}
        {aboutType === "agency" ? <Agency /> : <></>}
        {aboutType === "brand" ? <Brand /> : <></>}
        {aboutType === "tech" ? <Tech /> : <></>}
      </div>
      <Footer/>
    </div>
  );
}
