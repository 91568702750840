import {combineReducers} from '@reduxjs/toolkit';
import creatorListReducer from './slices/creatorListSlice';
import filtersReducer from './slices/filtersSlice';
import queryReducer from './slices/querySlice';
import loadingReducer from './slices/loadingSlice';
export const rootReducer = combineReducers({
  creatorList: creatorListReducer,
  filters: filtersReducer,
  query: queryReducer,
  tempQuery: queryReducer,
  loading: loadingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
