import { SocialIcon } from "react-social-icons";
import { nFormatter } from "../../../services/data-formatting";
import Result from "../../../types/result";
import { useNavigate } from "react-router-dom";
import { getCreatorsNaturalLanguage } from "../../../services/creator";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingCards from "../../loading/loadingCards/LoadingCards";
import {useAppDispatch, useAppSelector} from "../../../store/hooks.ts";
import {RootState} from "../../../store/root-reducer.ts";
import {setCreatorList} from "../../../store/slices/creatorListSlice.ts";
import {setLoading} from "../../../store/slices/loadingSlice.ts";

function CreatorCard(props: { creator: Result }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToCreator = () => {
    dispatch(setLoading(true));
    navigate(`/creator/${props.creator.metadata.anchor}`);
  };

  return (
    <>
      <div
        onClick={() => navigateToCreator()}
        className="order-first relative p-4 w-full bg-white hover:bg-gray-50 hover:shadow-xl cursor-pointer rounded-lg overflow-hidden border-solid border-2 border-gray-200 rounded-lg"
      >
        <img
          className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
          src={props.creator.metadata.img_link}
          alt={`${props.creator.metadata.anchor}'s profile`}
        />
        <div className="mt-3 text-center font-semibold">
          {props.creator.metadata.anchor}
        </div>
        <div className="flex justify-evenly mt-3">
          <div className="">
            <b>{nFormatter(props.creator.metadata.tik_tok_followers, 1)}</b>{" "}
            <p>Followers</p>
          </div>
          <div className="">
            <b>{nFormatter(props.creator.metadata.tik_tok_following, 1)}</b>{" "}
            <p>Following</p>
          </div>
          <div className="">
            <b>{nFormatter(props.creator.metadata.tik_tok_likes, 1)}</b>{" "}
            <p>Likes</p>
          </div>
        </div>
        <div className="px-3 pt-3">
          <div className="flex justify-evenly ">
            {props.creator.metadata.instagram && (
              <SocialIcon
                className="h-12 w-12"
                url={props.creator.metadata.instagram}
                target="_blank"
                rel="noreferrer"
              />
            )}
            {props.creator.metadata.tik_tok_profile && (
              <SocialIcon
                className="h-12 w-12"
                url={props.creator.metadata.tik_tok_profile}
                target="_blank"
                rel="noreferrer"
              />
            )}
            {props.creator.metadata.youtube && (
              <SocialIcon
                className="h-12 w-12"
                url={props.creator.metadata.youtube}
                target="_blank"
                rel="noreferrer"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default function CardList() {
  const [creatorIncrement,setCreatorIncrement]=useState<number>(100);
  const creatorList = useAppSelector((state:RootState) => state.creatorList.creatorList) ;
  const filters = useAppSelector((state:RootState) => state.filters.filters) ;
  const query = useAppSelector((state:RootState)=> state.query.query)
  const dispatch = useAppDispatch()

  const getMoreCreators = () => {
    setCreatorIncrement(creatorIncrement+100);
    getCreatorsNaturalLanguage(query, filters, creatorIncrement+100)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((results: Result[]) => {
        dispatch(setCreatorList(results));
      })
      .catch((error: Error) => console.log(error));
  };
  const hasMore = () => {
    if (creatorIncrement === 1000 || creatorList.length < 100) {
      return false;
    } else {
      return true;
    }
  }
  return (
    <>
    <InfiniteScroll
      dataLength={creatorList.length}
      next={getMoreCreators}
      hasMore={hasMore()}
      loader={<LoadingCards amount={4} />}
      endMessage={<p>No more data to load.</p>}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-4 w-full">
        {creatorList.map((creator) => {
          return (
            <CreatorCard
              key={creator.id}
              creator={creator}
            />
          );
        })}
      </div>
      </InfiniteScroll>
    </>
  );
}
