import { CheckBadgeIcon } from "@heroicons/react/20/solid";

export default function Agency() {
  return (
    <>
      <div className="bg-white px-6  lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-blue-600">
            Enhance
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Your Brand Deal Pipeline
          </h1>
          <p className="mt-6 text-xl leading-8">
            With Social Grab, we empower some of the world's largest brands with
            our next gen influencer discovery platform. We're here to connect
            your creators with those brands. It's collaboration made simple and
            effective.
          </p>
          <div className="mt-10 max-w-2xl">
            <ul className="mt-8 max-w-xl space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                <CheckBadgeIcon
                  className="mt-1 h-6 w-6 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Unreal Brand Network
                  </strong>{" "}
                  We enable the world's leading brands to use our influencer
                  discovery platforms. Let us be your bridge to success, helping
                  you and your creators get in touch with the right brands and
                  ensuring everyone gets paid.
                </span>
              </li>
              <li className="flex gap-x-3">
                <CheckBadgeIcon
                  className="mt-1 h-6 w-6 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Automated Media Kits
                  </strong>{" "}
                  Import your creators and generate media kits in seconds. With
                  first-party integrations we pull in their latest posts,
                  followers, and demographics daily so your media kits are
                  always up to date. Say goodbye to manual updates and hello to
                  convenience.
                </span>
              </li>

              <li className="flex gap-x-3">
                <CheckBadgeIcon
                  className="mt-1 h-6 w-6 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Fair and Friendly Pricing
                  </strong>{" "}
                  We're passionate about what we do, and we believe in
                  transparent, fair pricing. We don't hide any fees or skim off
                  the top. Just flat monthly rates that are as friendly to your
                  budget as we are to our clients.
                </span>
              </li>
            </ul>
            <p className="mt-8">
              We understand that agencies operate differently, and we embrace
              that diversity. If you're curious about our agency offerings and
              want a solution that fits like a glove, let's{" "}
              <a
                className="underline"
                href="https://www.calendly.com/mattdvertola"
              >
                get in touch. 
              </a>
              {" "}We're here to support you every step of the way.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
