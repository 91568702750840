import { useNavigate } from "react-router-dom";


export default function HeaderLarge() {
  const navigate = useNavigate();

  return (
    <div className="text-center mt-12">
      <img
        src="/sgLogo.png"
        alt="Social Grab Logo"
        className="h-32 w-32 mx-auto flex-shrink-0 cursor-pointer"
        onClick={() => navigate("/")}
      />
      <h1 className="sm:text-9xl text-6xl">Social Grab</h1>
      <h2 className="sm:text-3xl text-2xl">
        The easiest way to discover influencer talent
      </h2>
    </div>
  );
}
