export default function LandingFeature(props: {
  imgLocation: string;
  imgAlt: string;
  img: any;
  title: string;
  subTitle: string;
  description: string;
  features: Array<{ name: string; description: string; icon: any }>;
}) {
  return (
    <>
      {props.imgLocation === "left" ? (
        <FeatureImgLeft
          imgLocation={props.imgLocation}
          img={props.img}
          imgAlt={props.imgAlt}
          title={props.title}
          subTitle={props.subTitle}
          description={props.description}
          features={props.features}
        />
      ) : (
        <FeatureImgRight
          imgLocation={props.imgLocation}
          img={props.img}
          imgAlt={props.imgAlt}
          title={props.title}
          subTitle={props.subTitle}
          description={props.description}
          features={props.features}
        />
      )}
    </>
  );
}

export function FeatureImgRight(props: {
  imgLocation: string;
  img: any;
  imgAlt: string;
  title: string;
  subTitle: string;
  description: string;
  features: Array<{ name: string; description: string; icon: any }>;
}) {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                {props.subTitle}
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {props.title}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {props.description}
              </p>
              {props.features.length > 0 && (
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {props.features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              )}
            </div>
          </div>
          <img
            src={props.img}
            alt={props.imgAlt}
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}

export function FeatureImgLeft(props: {
  imgLocation: string;
  img: any;
  imgAlt: string;
  title: string;
  subTitle: string;
  description: string;
  features: Array<{ name: string; description: string; icon: any }>;
}) {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                {props.subTitle}
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {props.title}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {props.description}
              </p>
              {props.features.length > 0 && (
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {props.features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              )}
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={props.img}
              alt={props.imgAlt}
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
