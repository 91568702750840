import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useEffect } from "react";
import {Cookies} from "react-cookie";



export default function CookiesBanner() {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cookies = new Cookies();
  const [acceptedCookies, setAcceptedCookies] = React.useState(cookies.get("acceptedCookies"))
  const close = () => {
    cookies.set("acceptedCookies", true);
    setAcceptedCookies(true)
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  return (
    <>
      {!acceptedCookies ||
      acceptedCookies === undefined ? (
        <>
          <div className="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
            <p className="max-w-4xl text-sm leading-6 text-gray-900">
              By using this website you agree to use cookies that support basic
              site functionality. These are anonymous, stored only on your
              device, and not shared with any third parties.
            </p>
            <div className="flex flex-none items-center gap-x-5">
              <button onClick={() => close()}>
                <XMarkIcon className="text-black h-6 w-6" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
}
