
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";


export default function ViewChart(props: {
  videoStats: any;
  loading: boolean;
}) {

  ChartJS?.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      Filler
  );

  const data = {
    labels: Object.keys(props.videoStats).map(function (k) {
      return new Date(props.videoStats[k].date*1000).toLocaleDateString("en-US")
    }),
    datasets: [
      {
        fill: false,
        backgroundColor: "rgba(0, 0, 0)",
        borderColor: "rgb(0, 0, 0)",
        lineTension: 0.2,
        // pointRadius: 5,
        pointHoverRadius: 5,
        // pointBackgroundColor: '#FE5670',
        // pointBorderColor: '#ffffff',
        // pointBorderWidth: 1.5,
        label: "TikTok Views",
        data: Object.keys(props.videoStats).map(function (k) {
          return props.videoStats[k].views;
        }),
      },
    //   {
    //     fill: true,
    //     backgroundColor: "rgba(53, 162, 235, 0)",
    //     borderColor: "rgba(53, 162, 235, 0)",
    //     lineTension: 0.2,
    //     pointRadius: 0,
    //     pointHoverRadius: 0,
    //     // pointBackgroundColor: '#FE5670',
    //     // pointBorderColor: '#ffffff',
    //     pointBorderWidth: 0,
    //     label: "Comments",
    //     data: Object.keys(props.videoStats).map(function (k) {
    //       return props.videoStats[k].comments;
    //     }),
    //   },
    //   {
    //     fill: true,
    //     backgroundColor: "rgba(53, 162, 235, 0)",
    //     borderColor: "rgba(53, 162, 235, 0)",
    //     lineTension: 0.2,
    //     pointRadius: 0,
    //     pointHoverRadius: 0,
    //     // pointBackgroundColor: '#FE5670',
    //     // pointBorderColor: '#ffffff',
    //     pointBorderWidth: 0,
    //     label: "Likes",
    //     data: Object.keys(props.videoStats).map(function (k) {
    //       return props.videoStats[k].likes;
    //     }),
    //   },
    ],
  };

  return (
    <>
      {!props.loading && (
        <div className="mt-5 w-full overflow-hidden rounded-xl border border-gray-200 sm:mx-1 my-1">
          <div className="gap-x-4 bg-gray-100 border-gray-300 p-1">
            <div className="text-xl capitalize p-2">View Trends</div>
          </div>
          <div className="bg-white px-4 h-96 flex justify-center">
            {!props.loading && <Line data={data} />}
          </div>
        </div>
      )}
    </>
  );
}
