import { useNavigate } from "react-router-dom";


export default function Menu() {
  let navigate = useNavigate();

  return (
    <div className="max-w-full">
      <div className="text-center font-semibold text-2xl">I am a...</div>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-8 mt-8 mx-2">
        <button
          onClick={() => navigate("/about/creator")}
          className="w-full font-bold text-xl outline outline-4 outline-black p-8 rounded-lg hover:bg-gray-50 hover:drop-shadow-lg"
        >
          Creator
        </button>
        <button
          onClick={() => navigate("/about/agency")}
          className="w-full font-bold text-xl outline outline-4 outline-black p-8 rounded-lg hover:bg-gray-50 hover:drop-shadow-lg"
        >
          Agency
        </button>
        <button
          onClick={() => navigate("/about/brand")}
          className="w-full font-bold text-xl outline outline-4 outline-black p-8 rounded-lg hover:bg-gray-50 hover:drop-shadow-lg"
        >
          Brand
        </button>
        <button
          onClick={() => navigate("/about/tech")}
          className="w-full font-bold text-xl outline outline-4 outline-black p-8 rounded-lg hover:bg-gray-50 hover:drop-shadow-lg"
        >
          Nerd
        </button>
      </div>
    </div>
  );
}
