import { CreatorChartDataItem } from "../types/creator";

export function nFormatter(
  num: number | string | null | undefined,
  digits: number
) {
  if (num === null || num === undefined) {
    return null;
  }
  if (typeof num === "string") {
    num = parseInt(num);
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      if (typeof num === "string") {
        num = parseInt(num);
        if (num === null || num === undefined) {
          return 0;
        }
      } else {
        num = num || 0;
      }
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}
export function getKeysWithHighestValue(o: { [x: string]: number; }, n: number | undefined) {
  try{
    var keys = Object.keys(o);
    keys.sort(function (a, b) {
      return o[b] - o[a];
    });
    return keys.slice(0, n);
  }
  catch(e){
    return [];
  }
}

export function prepareDataForChartjs(data: CreatorChartDataItem[], keys: string[]) {
  // Sorting data based on 'time_stamp'
  data.sort((a, b) => a.time_stamp.localeCompare(b.time_stamp));

  // Extracting all timestamps for x-axis
  const labels = data.map((item) => item.time_stamp);

  const datasets = keys.map((key) => ({
    label: key,
    data: data.map((item) => item[key]),
    fill: false,
    // Add any other dataset properties like borderColor, etc.
  }));

  return {
    labels,
    datasets,
  };
};