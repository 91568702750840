import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function HeaderSmall() {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [authed, setAuthed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentSession()
      .then((res) => {
        let idToken = res.getIdToken();
        setFName(idToken.payload.given_name);
        setLName(idToken.payload.family_name);
        setAuthed(true);
      })
      .catch((err) => {
        setAuthed(false);
      });
  }, [authed]);

  const signOut = () => {
    Auth.signOut()
      .then((data) => {
        navigate("/");
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="text-center mt-8 mx-auto px-2 flex justify-between">
      <img
        src="/sgLogo.png"
        alt="Social Grab Logo"
        className="h-12 w-12 flex-shrink-0 cursor-pointer"
        onClick={() => navigate("/")}
      />
      {authed && (
        <span className="inline-flex h-12 w-12 items-center justify-center rounded-lg bg-white border-solid border-2 border-black hover:bg-gray-50 cursor-pointer">
          <span
            onClick={() => {
              signOut();
            }}
            className="text-lg font-semibold leading-none text-black"
          >{`${fName[0]}${lName[0]}`}</span>
        </span>
      )}
    </div>
  );
}
