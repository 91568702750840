import { SocialIcon } from "react-social-icons";

export default function Social(props: { creator: any }) {
  if (Object.keys(props.creator).includes("tik_tok_link")) {
    if (
      !props.creator.tik_tok_link.includes("https://") &&
      !props.creator.tik_tok_link.includes("http://")
    ) {
      props.creator.tik_tok_link = `https://${props.creator.tik_tok_link}`;
    }
  }
  return (
    <div className="flex justify-evenly my-4 ">
      {props.creator.instagram && !props.creator.insta_followers_count && (
        <SocialIcon
          className="h-12 w-12"
          url={props.creator.instagram}
          target="_blank"
          rel="noreferrer"
        />
      )}
      {props.creator.youtube && !props.creator.youtube_subscribers && (
        <SocialIcon
          className="h-12 w-12"
          url={props.creator.youtube}
          target="_blank"
          rel="noreferrer"
        />
      )}
      {props.creator.twitch && (
        <SocialIcon
          className="h-12 w-12"
          url={props.creator.twitch}
          target="_blank"
          rel="noreferrer"
        />
      )}
      {props.creator.twitter && (
        <SocialIcon
          className="h-12 w-12"
          url={props.creator.twitter}
          target="_blank"
          rel="noreferrer"
        />
      )}
      {props.creator.email && (
        <SocialIcon
          className="h-12 w-12"
          url={`mailto:${props.creator.email}`}
          network="email"
        />
      )}

      {props.creator.tik_tok_link && props.creator.tik_tok_link !== "" && (
        <SocialIcon
          target="_blank"
          rel="noreferrer"
          className="h-12 w-12"
          url={`${props.creator.tik_tok_link}`}
        />
      )}
    </div>
  );
}
