import HeaderSmall from "../components/header/headerSmall/HeaderSmall";
import { Amplify } from "aws-amplify";
import {
  Authenticator,
  useAuthenticator,
  // Theme,
  // ThemeProvider,
  // useTheme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Cookies} from "react-cookie";
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
});
export default function Auth() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    if (authStatus === "authenticated") {
      cookies.set("signedUp", true);
      navigate("/results");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);
  const formFields = {
    resetPassword: {
      username: {
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email:",
      },
    },
    signIn: {
      username: {
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email:",
      },
    },
    signUp: {
      username: {
        placeholder: "Enter Your Email Here",
        isRequired: true,
        label: "Email:",
      },
      given_name: {
        placeholder: "Enter Your First Name Here",
        isRequired: true,
        label: "First Name:",
      },
      family_name: {
        placeholder: "Enter Your Last Name Here",
        isRequired: true,
        label: "Last Name:",
      },
    },
  };
  return (
    <div className="mx-auto">
      <HeaderSmall />
      <div className="isolate bg-white px-6 lg:px-8 mt-10">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Sign up to continue!
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            To see individual creators and run more searches, 
            you will need to sign up. 
            If you would like to learn more first, check out our{" "}
            <a href="/about" className="underline">
              about page.
            </a>
          </p>
        </div>
      </div>
      <Authenticator signUpAttributes={['given_name','family_name']} formFields={formFields} className="mt-4" />
      <div className="mx-auto max-w-2xl text-center">
      <p className="mt-2 text-sm leading-8 text-gray-600">
        By signing up, you agree to being contacted by Social Grab.
      </p>
      </div>
    </div>
  );
}
