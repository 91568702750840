import ReactCountryFlag from "react-country-flag";
const countries = require("country-data").countries;
export default function Header(props: { creator: any }) {
  return (
    <>
      {/* <div className="rounded-md border border-gray-200 border-2"> */}
      <div>
        <div className="flex justify-evenly mt-12">
          <img
            className="h-48 w-48 rounded-full"
            alt="creator profile"
            src={props.creator.img_link}
          ></img>
        </div>
        <div className="flex justify-evenly mt-4">
          <div className="text-2xl text-center font-bold">
            {props.creator.tik_tok_full_name}
          </div>
        </div>
        <div className="flex justify-evenly">
          <div className="text-md text-center">@{props.creator.anchor}</div>
        </div>
        <div className="flex justify-evenly mt-2">
          <div className="text-md text-center">{props.creator.tik_tok_bio}</div>
        </div>
      </div>
      <div className="flex justify-evenly mt-4">
        <div className="text-center">
          {props.creator.country && (
            <>
              <ReactCountryFlag
                svg
                className="text-2xl text-center"
                countryCode={props.creator.country}
              />
              <p className="text-center">
                {countries[props.creator.country].name}
              </p>
            </>
          )}
        </div>
        {props.creator.gender && (
          <div>
            <div>Gender</div>
            <div>{props.creator.gender}</div>
          </div>
        )}
        {props.creator.category && (
          <div>
            <div>Category</div>
            <div>{props.creator.category}</div>
          </div>
        )}
      </div>
    </>
  );
}
