
import CreatorStatsCard from "../statsCard/StatsCard.tsx";


export default function Stats(props:{creator: any}) {

  return (
    <div className="md:flex md:justify-evenly w-full">
    <CreatorStatsCard creator={props.creator} platform="tiktok"></CreatorStatsCard>
    {props.creator.insta_follows_count && (
      <CreatorStatsCard creator={props.creator} platform="instagram"></CreatorStatsCard>
    )}
    {props.creator.youtube_subscribers && (
      <CreatorStatsCard creator={props.creator} platform="youtube"></CreatorStatsCard>
    )}
    
    </div>
  );
}
