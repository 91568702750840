import { useNavigate } from "react-router-dom";
import LoadingCards from "../components/loading/loadingCards/LoadingCards.tsx";
import CardList from "../components/creator/cardList/CardList.tsx";
import SearchBar from "../components/search-bar";
import Result from "../types/result";
import { useEffect } from "react";
import HeaderSmall from "../components/header/headerSmall/HeaderSmall.tsx";
import ResultsFilters from "../components/results-filters";
import { TrackGoogleAnalyticsEvent } from "../services/analytics";
import { getCreatorsNaturalLanguage } from "../services/creator";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAppDispatch, useAppSelector } from "../store/hooks.ts";
import { RootState } from "../store/root-reducer.ts";
import { setLoading } from "../store/slices/loadingSlice.ts";
import { setCreatorList } from "../store/slices/creatorListSlice.ts";
import { Cookies } from "react-cookie";
import { Auth } from "aws-amplify";
export default function Results() {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) => state.loading.loading);
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const query = useAppSelector((state: RootState) => state.query.query);

  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get("searchCount") >= 3 && authStatus !== "authenticated") {
      navigate("/sign-up");
    } else {
      search(filters, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, query]);

  const search = (filters: object, query: string) => {
    dispatch(setLoading(true));
    TrackGoogleAnalyticsEvent("Searched", "user search term", query, {
      search_term: query,
    });
    try {
      Auth.currentSession().then((res) => {
        getCreatorsNaturalLanguage(
          query,
          filters,
          100,
          res.getIdToken().getJwtToken()
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((results: Result[]) => {
            dispatch(setCreatorList(results));
            dispatch(setLoading(false));
          })
          .catch((error: Error) => console.log(error));
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderSmall />
      <SearchBar showUsernameDropdown={false} />
      <>{query !== "" ? <ResultsFilters /> : <></>}</>
      {!loading ? <CardList /> : <LoadingCards amount={8} />}
    </>
  );
}
