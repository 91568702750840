import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";
// function to trigger custom google analytics event
export const triggerAnalytics = (query: string) => {
  ReactGA.event({
    category: "search",
    action: "searched",
    label: query,
  });
};

export const TrackGoogleAnalyticsEvent = (
  category: any,
  event_name: string | UaEventOptions,
  label: any,
  data: any
) => {
  console.log("GA event:", category, ":", event_name, ":", label);

  let event_params = {
    category,
    label,
    ...data,
  };
  // Send GA4 Event
  ReactGA.event(event_name, event_params);
};
