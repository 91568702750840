import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../store/hooks.ts";
import {setFilters} from "../store/slices/filtersSlice.ts";
import {RootState} from "../store/root-reducer.ts";
import {setQuery, setTempQuery} from "../store/slices/querySlice.ts";
import {Cookies} from "react-cookie";
export default function SearchBar(props: {
  showUsernameDropdown: boolean;
}) {

  const [dropDownSelectedOption, setDropDownSelectedOption] =
    useState("Content");
  const inputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const filters = useAppSelector((state:RootState)=> state.filters.filters)
  // const query = useAppSelector((state:RootState)=> state.query.query)
  const tempQuery = useAppSelector((state:RootState)=> state.query.tempQuery)

  const cookies = new Cookies();

  const options = ["Username", "Content"];

  const search = () => {
    if (dropDownSelectedOption === "Username") {
      // props.setQuery(tempQuery);
      dispatch(setFilters({ ...{ username: tempQuery } }));
   }else {
      dispatch(setFilters({ ...filters }))
    }
    if (cookies.get("searchCount") === undefined) {
      cookies.set("searchCount", 0);
    }
    let sCount = Number(cookies.get("searchCount"));
    cookies.set("searchCount", (sCount += 1));
    navigate("/results");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      dispatch(setQuery(tempQuery));
      search();
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTempQuery(event.target.value));
  };

  const handleDropDownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setDropDownSelectedOption(event.target.value);
    event.target.blur();
  };

  return (
    <div className="my-10 mx-2">
      <div className="relative mt-2 flex items-center rounded-lg border border-gray-300">
        {props.showUsernameDropdown && (
          <div>
            <select
              value={dropDownSelectedOption}
              onChange={handleDropDownChange}
              className="flex-shrink-0 z-10 inline-flex py-[19px] text-sm font-medium text-center text-gray-900 bg-gray-100 rounded-s-lg hover:bg-gray-200 focus:outline-none px-4"
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="w-full">
          <input
            type="text"
            name="search"
            id="search"
            ref={inputRef}
            placeholder="Search"
            className="block appearance-none rounded-lg w-full text-lg py-4 px-2 pr-14 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
            value={tempQuery}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
          />
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <button
              onClick={search}
              className="inline-flex bg-white items-center rounded-xl border border-gray-200 px-4 font-sans text-xl text-gray-400 hover:bg-gray-50"
            >
              Enter ↲
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
