import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Result from "../../types/result.ts";

interface CreatorListState {
    creatorList: Result[];
}

const initialState: CreatorListState = {
    creatorList: [],
};

const creatorListSlice = createSlice({
    name: 'creatorList',
    initialState,
    reducers: {
        setCreatorList: (state, action: PayloadAction<any[]>) => {
            state.creatorList = action.payload;
        },
    },
});

export const { setCreatorList } = creatorListSlice.actions;
export default creatorListSlice.reducer;
