import { SocialIcon } from "react-social-icons";
import { nFormatter } from "../../../services/data-formatting.ts";
export default function CreatorStatsTikTok(props:{creator:any, platform:string}) {

  return (
    <div className="w-full overflow-hidden rounded-xl border border-gray-200 sm:mx-1 my-1">
      {props.platform === "tiktok" && (
        <>
          <div className="gap-x-4 bg-gray-100 border-gray-300 p-1">
            <SocialIcon
              className="h-12 w-12 bg-white rounded-full"
              url={props.creator.tik_tok_profile}
              target="_blank"
              rel="noreferrer"
            />
          </div>
          <div className="bg-white p-4">
            <dd className="flex justify-evenly">
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.tik_tok_followers), 1)}</b>{" "}
                <p>Followers</p>
              </div>
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.tik_tok_following), 1)}</b>{" "}
                <p>Following</p>
              </div>
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.tik_tok_likes), 1)}</b>{" "}
                <p>Likes</p>
              </div>
            </dd>
            <dd className="flex justify-evenly mt-3">
              {props.creator.engagement && (
                <div className="w-1/3 text-center">
                <b>{props.creator.engagement} %</b>{" "}
                <p>Avg Engagement</p>
              </div>
              )}
              {!props.creator.engagement && (
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.tik_tok_comments_avg), 1)}</b>{" "}
                <p>Avg Comments</p>
              </div>
              )}
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.tik_tok_likes_avg), 1)}</b>{" "}
                <p>Avg Likes</p>
              </div>
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.tik_tok_views_mean), 1)}</b>
                <p>Avg Views</p>
              </div>
            </dd>
          </div>
        </>
      )}
      {props.platform === "instagram" && (
        <>
          <div className="gap-x-4 bg-gray-100 border-gray-200 p-1">
            <SocialIcon
              className="h-12 w-12 bg-white rounded-full"
              url={props.creator.instagram}
              target="_blank"
              rel="noreferrer"
            />
          </div>
          <div className="bg-white p-4 h-full">
            <dd className="flex justify-between">
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.insta_followers_count), 1)}</b>{" "}
                <p>Followers</p>
              </div>
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.insta_follows_count), 1)}</b>{" "}
                <p>Following</p>
              </div>
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.insta_media_count), 1)}</b>{" "}
                <p>Posts</p>
              </div>
            </dd>
          </div>
        </>
      )}
      {props.platform === "youtube" && (
        <>
          <div className="gap-x-4 bg-gray-100 border-gray-200 p-1">
            <SocialIcon
              className="h-12 w-12 bg-white rounded-full"
              url={props.creator.youtube}
              target="_blank"
              rel="noreferrer"
            />
          </div>
          <div className="bg-white p-4 h-full">
            <dd className="flex justify-evenly">
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.youtube_subscribers), 1)}</b>{" "}
                <p>Subscribers</p>
              </div>
              {/* <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.insta_follows_count), 1)}</b>{" "}
                <p>Following</p>
              </div> */}
              <div className="w-1/3 text-center">
                <b>{nFormatter(parseInt(props.creator.youtube_video_count), 1)}</b>{" "}
                <p>Videos</p>
              </div>
            </dd>
          </div>
        </>
      )}

    </div>
  );
}
