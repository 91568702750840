

export default function Creator() {
  return (
    <>
      <div className="bg-white px-6  lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-blue-600">
            Unveiling
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Your Hub for Sponsorships
          </h1>
          <p className="mt-6 text-xl leading-8">
            At Social Grab, we've made it our mission to connect influencers
            with leading brands for exciting collaborations and sponsorships.
            The best part: if you're a creator, joining us is entirely free!
            We're dedicated to helping you secure more paid brand promotions.
          </p>
          <div className="mt-10 max-w-2xl">
            <p className="mt-6 text-lg leading-8">
              By linking your social media profiles with our platform, you
              ensure that we share the most relevant and up-to-date content.
              This not only enhances your visibility but also builds confidence
              with brands, potentially leading to increased sponsorships.
            </p>
            <p className="mt-6 text-lg leading-8">
              Additionally, we will give you a kick-ass media kit that you can
              share with brands, managers, etc. If we've found your socials, you
              should already have one at
              <p className="underline">https://socialgrab.io/creator/your-tiktok-username.</p>
            </p>
            <p className="mt-6 text-lg leading-8">
              Can't find yourself on our platform? No worries! Just reach out,
              and we'll happily guide you through the process to make sure
              you're part of the Social Grab community. We're here to make your
              voice heard and your creativity shine.
            </p>
            <p className="mt-6 text-lg leading-8">
              Social Grab is all about facilitating connections, promoting
              creativity, and building partnerships. Join us today, and let's
              create something extraordinary together. Your audience and
              opportunity awaits.
            </p>
            <p className="mt-6 text-lg leading-8">
              If, for any reason, you wish to be excluded from our platform, we
              respect your decision. Simply let us know, and we'll ensure that
              we're sharing only the information you're comfortable with.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
