import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCreator } from "../services/creator";
import { Auth } from "aws-amplify";
import Header from "../components/creator/header/Header";
import Social from "../components/creator/social/Social";
import Stats from "../components/creator/stats/Stats";
import HeaderSmall from "../components/header/headerSmall/HeaderSmall";
import TopContent from "../components/creator/topContent/TopContent";
import LoadingCreator from "../components/loading/loadingCreator/LoadingCreator";
import AudienceChart from "../components/creator/audienceChart/AudienceChart";
import ViewChart from "../components/creator/viewChart/ViewChart";
import {setLoading} from "../store/slices/loadingSlice.ts";
import {useAppDispatch} from "../store/hooks.ts";
export default function IndivCreator() {
  const [creator, setCreator] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let { anchor } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  dispatch(setLoading(true));
  useEffect(() => {
    try {
      Auth.currentSession()
        .then((res) => {
          getCreator(anchor, res.getIdToken().getJwtToken())
            .then((response: any) => {
              response.json().then((result: any) => {
                setCreator(result);
                setIsLoading(false);
              });
            })
            .catch((error: Error) => console.log(error));
        })
        .catch((error: Error) => navigate("/sign-up"));
    } catch (error) {
      navigate("/sign-up");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor]);
  return (
    <>
      <HeaderSmall />

      {isLoading && <LoadingCreator />}
      {!isLoading && (
        <div className="sm:p-3 sm:mx-3">
          <Header creator={creator} />
          <Social creator={creator} />
          <Stats creator={creator} />
          <TopContent creator={creator} loading={isLoading} />
          <ViewChart
            videoStats={creator.video_stats}
            loading={isLoading}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 px-1">
            <AudienceChart
              header="YouTube Subscriber Growth"
              creator={creator}
              platform="youtube_subscribers"
            />
            <AudienceChart
              header="Tiktok Follower Growth"
              creator={creator}
              platform="tik_tok_followers"
            />
            <AudienceChart
              header="Instagram Follower Growth"
              creator={creator}
              platform="insta_followers_count"
            />
          </div>
        </div>
      )}
    </>
  );
}
