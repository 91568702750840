import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Filters from "../../types/filters.ts";

interface FiltersState {
    filters: Filters;
}

const initialState: FiltersState = {
    filters: {},
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<{ username?: string }>) => {
            state.filters = action.payload;
        },
    },
});

export const { setFilters } = filtersSlice.actions;
export default filtersSlice.reducer;
