import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface QueryState {
    query: string;
    tempQuery: string;
}

const initialState: QueryState = {
    query: '',
    tempQuery: '',
};

const querySlice = createSlice({
    name: 'query',
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
        },
        setTempQuery: (state, action: PayloadAction<string>) => {
            state.tempQuery = action.payload;
        }
    },
});

export const { setQuery } = querySlice.actions;
export const { setTempQuery } = querySlice.actions;
export default querySlice.reducer;
