import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { nFormatter } from "../services/data-formatting";
import {useAppDispatch, useAppSelector} from "../store/hooks.ts";
import {RootState} from "../store/root-reducer.ts";
import {setFilters} from "../store/slices/filtersSlice.ts";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown(props: {
      position: string;
      name: string;
      displayValue: string;
      options: Array<string | number | null>;
    }) {

    const dispatch = useAppDispatch();
    const filters = useAppSelector((state:RootState)=> state.filters.filters)

  return (
    <Menu as="div" className="relative inline-block text-left sm:px-2">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {props.displayValue}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Menu.Items
        className={`absolute ${props.position}-0 z-50 mt-2 w-56 origin-top-${props.position} rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
      >
        <div className="py-1">
          {props.options.map((option) => {
            if (typeof option == "number") {
              var cleanOption = nFormatter(option, 0);
            } else {
              cleanOption = option;
            }
            return (
              <Menu.Item key={option || "option"}>
                {({ active }) => (
                  <option
                    onClick={() => {
                        dispatch(setFilters({
                        ...filters,
                        [props.name]: option,
                      }));
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {cleanOption}
                  </option>
                )}
              </Menu.Item>
            );
          })}
        </div>
      </Menu.Items>
    </Menu>
  );
}
