import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import techDiag from "../../../assets/img/tech-diag.png";
import dharmesh from "../../../assets/img/dharmesh.jpeg";
export default function Tech() {
  return (
    <>
      <div className="bg-white px-6  lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-blue-600">
            Unleashing
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            LLM Powered Creator Discovery
          </h1>
          <p className="mt-6 text-xl leading-8">
            We're harnessing the power of LLM MapReduce, sparse dense
            embeddings, and vector databases to create a contextually and
            semantically aware creator search engine. Entirely over engineered?
            Probs. But it's f*cking sick & no one else has tried it yet.
          </p>
          <div className="mt-10 max-w-2xl">
            <p></p>
            <ul className="mt-8 max-w-xl space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Feature Rich Creator Profiles
                  </strong>{" "}
                  Creator data sourced from several platforms including
                  followers, post content, biographies, audience demographics
                  and more.
                </span>
              </li>
              <li className="flex gap-x-3">
                <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    GPT 4 MapReduce & Embedding
                  </strong>{" "}
                  Generate natural language summaries for creator data and embed
                  them in a vector space.
                </span>
              </li>
              <li className="flex gap-x-3">
                <CheckCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Indexing & Natural Language Search
                  </strong>{" "}
                  Add vector embeddings to a pinecone vector index for fast
                  similarity search. Embed user search queries and compare them
                  against the index.
                </span>
              </li>
            </ul>
            <p className="mt-8">
              So all-in-all we're doing some pretty cool stuff. If you want to
              learn more about our tech offerings such as increased data access,
              unrestricted search limits, or custom API integrations{" "}
              <a
                href="https://www.calendly.com/mattdvertola"
                className="underline"
              >
                {" "}
                let's chat.
              </a>
            </p>
            <figure className="mt-16">
              <img
                className="aspect-video rounded-xl bg-gray-50 object-cover"
                src={techDiag}
                alt=""
              />
              <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
                <InformationCircleIcon
                  className="mt-0.5 h-5 w-5 flex-none text-gray-300"
                  aria-hidden="true"
                />
                Creator embedding, vector indexing and natural language search
                diagram
              </figcaption>
            </figure>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              Why vector embeddings?
            </h2>
            <p className="mt-6">
              One of the hard things about creator discovery is that you can
              search with keywords and hashtags all day long but you are still
              going to miss out on a lot of creators. For example, a good comedy
              creator isn't going to run around using "#comedy" all the time.
            </p>
            <p className="mt-6">
              Thats where the beauty of vector embeddings comes in. We can take
              a complex & super feature dense object like all of a influencer's
              social media posts and embed it in a vector space. Then when it
              comes down to retrieving creators we can semantically compare the
              user's search query to the vector embeddings of the creators.
            </p>
            <p className="mt-6">
              So this semantic comparison thing... this allows us to do some
              pretty cool stuff. One of my favorite examples is that we can
              search "taco bell" and get hispanic food creators as a response.
              It's this directionally similar relationship that opens the door
              to finding creators based on the essence of their content rather
              than just the keywords they use.
            </p>
            <figure className="mt-10 border-l border-blue-600 pl-9">
              <blockquote className="font-semibold text-gray-900">
                <p>
                  “If I can take a dataset and convert it to vector embeddings,
                  this will allow people to find things in a different way than
                  they’ve ever been able to do before.”
                </p>
              </blockquote>
              <figcaption className="mt-6 flex gap-x-4">
                <img
                  className="h-8 w-8 flex-none rounded-full bg-gray-50"
                  src={dharmesh}
                  alt=""
                />
                <div className="text-sm leading-6">
                  <strong className="font-semibold text-gray-900">
                    Dharmesh Shah
                  </strong>{" "}
                  CTO – HubSpot
                </div>
              </figcaption>
            </figure>
            <p className="mt-10">
              While we may be biased, we have to agree with Dharmesh. The
              complex relationship between creators and their content is
              something that is proving to be a pretty interesting application
              of vector embeddings.
            </p>
          </div>

          <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Our approach & architecture
            </h2>
            <p className="mt-6">
              While the creator search problem is a complex one, we've been able
              apply this same framework of vector embeddings to a variety of
              very interesting problems.
            </p>
            <p className="mt-6">
              Our approach is a lightweight, serverless architecture that leaves
              little to no overhead, can be stood up in a matter of minutes, and
              is highly scalable. We've been able to apply this architecture to
              a variety of problems and industries.
            </p>
            <p className="mt-6">
              If you or your team are interested in setting up our framework for
              your own use case, we'd love to chat.{" "}
              <a
                href="https://www.calendly.com/mattdvertola"
                className="underline"
              >
                Contact us
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
