import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
} from "chart.js";
import { getCreatorTimeSeriesChart } from "../../../services/creator";
import { Auth } from "aws-amplify";

type Dataset = {
  label: string;
  data: string[];
  fill: boolean;
  borderColor: string;
  backgroundColor: string;
};

type ChartData = {
  labels: string[];
  datasets: Dataset[];
};

function filterDatasets(chartData: ChartData, platform: string): ChartData {
  // Clone the chartData to avoid mutating the original object
  const result = JSON.parse(JSON.stringify(chartData)) as ChartData;

  // Filter datasets
  result.datasets = result.datasets.filter(
    (dataset) => dataset.label === platform
  );

  return result;
}

export default function AudienceChart(props: {
  creator: any;
  platform: string;
  header: string;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>({});
  const [render, setRender] = useState<boolean>(true);
  // const [chartOptions, setChartOptions] = useState<ChartOptions>({});

  useEffect(() => {
    Chart.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      TimeScale
    );
    Auth.currentSession().then((res) => {
      getCreatorTimeSeriesChart(
        props.creator.anchor,
        res.getIdToken().getJwtToken()
      )
        .then((response: any) => {
          response.json().then((result: any) => {
            const filteredChartData = filterDatasets(
              result.chartData,
              props.platform
            );
            if (filteredChartData.datasets.length === 0) {
              setLoading(false);
              setRender(false);
              return;
            }
            setChartData(filteredChartData);
            setLoading(false);
          });
        })
        .catch((error: Error) => {});
    });
  }, [props.creator, props.platform]);

  return (
    <>
      {render && (
        <div className="mt-5 w-full overflow-hidden rounded-xl border border-gray-200 sm:mx-1 my-1">
          <div className="gap-x-4 bg-gray-100 border-gray-300 p-1">
            <div className="text-xl capitalize p-2">{props.header}</div>
          </div>
          <div className="bg-white px-4 w-full h-full">
            {!loading && <Line data={chartData} />}
          </div>
        </div>
      )}
    </>
  );
}
