import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/home";
import Results from "./routes/results";
import ReactGA from "react-ga4";
import About from "./routes/about";
import Auth from "./routes/auth";
import { Authenticator } from "@aws-amplify/ui-react";
import Creator from "./routes/creator";
function App() {

  ReactGA.initialize(`${process.env.REACT_APP_GA4_ID}`);
  return (
    <div className="container mx-auto h-screen">
      <Authenticator.Provider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={ <Home /> }
            />
            <Route
              path="/results"
              element={ <Results /> }
            />
            <Route path="/sign-up" element={ <Auth /> } />
            <Route path="/about" element={ <About /> } />
            <Route path="/about/:aboutType" element={ <About /> } />
             <Route
              path="/creator/:anchor"
              element={ <Creator /> }
            />
            {/* <Route path="/contact" element={<Contact />} /> */}
            
          </Routes>
        </Router>
      </Authenticator.Provider>
    </div>
  );
}

export default App;
