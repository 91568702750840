export default function LoadingCards(props: { amount: number }) {
  // create an array of length props.amount
  const arr = Array.from({ length: props.amount }, (v, i) => i);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-4 w-full">
        {arr.map((item, index) => (
          <div key={index} className="relative border-gray-200 border-2 p-4 w-full bg-white overflow-hidden shadow hover:shadow-md rounded-lg">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                <div className="space-y-2">
                  <div className="h-4 bg-gray-400 rounded"></div>
                  <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                  <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
