import Dropdown from "./dropdown";
import { nFormatter } from "../services/data-formatting";
import {useAppDispatch, useAppSelector} from "../store/hooks.ts";
import {setFilters} from "../store/slices/filtersSlice.ts";
import {RootState} from "../store/root-reducer.ts";


const countryOptions = ["US", "GB", "AU"];
const followersOptions = [
  10000,25000,50000,100000,250000,500000,1000000,2000000,5000000,10000000,20000000
];
const viewsOptions = [
  1000, 10000, 25000, 50000, 100000, 500000, 1000000, 5000000, 10000000,
];

export default function ResultsFilters() {
    const dispatch = useAppDispatch();
    const filters = useAppSelector((state:RootState)=> state.filters.filters);

    return (
    <div className="h-12  grid grid-cols-4">
      <Dropdown
        position="left"
        displayValue={filters.country || "country"}
        name={"country"}
        options={countryOptions}
      />
      <Dropdown
        position="left"
        name={"followers"}
        displayValue={nFormatter(filters.followers,1) || "followers"}
        options={followersOptions}
      />
      <Dropdown
        position="right"
        name={"views"}
        displayValue={nFormatter(filters.views,1) || "views"}
        options={viewsOptions}
      />

      <div className="relative inline-block text-left px-2">
        <div>
          <button onClick={()=>dispatch(setFilters({}))} className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}
