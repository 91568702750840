export default function DataInfo() {
  return (
    <>
      <div className="bg-gray-50 rounded-lg">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl capitalize font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              We help you find relevant creators fast.
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Social Grab finds, filters and tracks millions of creators across
              all major social platforms daily to help industry professionals
              find and contact the perfect creators.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
