import CookiesBanner from "../components/cookies-banner";
import Footer from "../components/footer";
import DataInfo from "../components/landing-data-info";
import LandingFeature from "../components/landing-feature";
import LandingHero from "../components/landing-hero";
import Pricing from "../components/pricing";
import Filters from "../types/filters";
import resultsScreenshot from "../assets/img/resultsScreenshot.png";
import performanceTrends from "../assets/img/performanceTrends.png";
import creatorView from "../assets/img/creatorView.png";
export default function Home(props: {
  setQuery: Function;
  query: string;
  setCreatorList: Function;
  setLoading: Function;
  setFilters: Function;
  filters: Filters;
  loading: boolean;
  cookies: any;
}) {
  return (
    <div>
      {/* hero section */}
      <LandingHero />
      {/* section talking about data we have/ collect */}
      <DataInfo />
      {/* section for interactive example walkthrough */}

      {/* product features emphasis on data we have */}
      <LandingFeature
        title="Find Creators In Seconds"
        subTitle="Simple search"
        description="Our advanced searching mechanisms enable the simplest and most straightforward searching experience. Type something like 'mom creators posting about home diy' and we'll find the creators for you."
        img={resultsScreenshot}
        imgAlt=""
        imgLocation="right"
        features={[]}
      />
      <LandingFeature
        title="View Performance Over Time"
        subTitle="Advanced Analytics"
        description="Our consistent creator data refreshes allow you to view creator data over time. We collect across all platforms via first party integrations."
        img={performanceTrends}
        imgAlt=""
        imgLocation="left"
        features={[]}
      />
      <LandingFeature
        title="Reach Out Directly"
        subTitle="Up-to-Date Contact Info"
        description="We provide contact information for creators to make it easy to reach out to them. We update this information constantly to ensure it remains relevant. We provide insights into things such as a creators management status."
        img={creatorView}
        imgAlt=""
        imgLocation="right"
        features={[]}
      />
      {/* who uses social grab & who its for */}

      {/* social grab advantage */}
      {/* data statistics/ summary */}
      {/* <PlatformStatistics /> */}
      {/* pricing/ get started */}
      <Pricing/>
      {/* footer */}

      <div className="text-center">
        <a
          href="about"
          className="mt-10 text-center hover:underline hover:underline-offset-1 text-md leading-5 text-black hover:text-gray-500"
        >
          About Us
        </a>
      </div>
      <Footer />
      <CookiesBanner />
    </div>
  );
}
