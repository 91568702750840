import { CheckBadgeIcon } from "@heroicons/react/20/solid";

export default function Brand() {
  return (
    <>
      <div className="bg-white px-6  lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-blue-600">
            Unveiling
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Your Personal Genie for Finding Creators
          </h1>
          <p className="mt-6 text-xl leading-8">
            Meet Social Grab, where we blend cutting-edge AI with a touch of
            magic to help you discover the perfect influencers for your brand.
            We don't just understand the language; we get the essence of
            creators and their craft.
          </p>
          <div className="mt-10 max-w-2xl">
            <ul className="mt-8 max-w-xl space-y-8 text-gray-600">
              <li className="flex gap-x-3">
                <CheckBadgeIcon
                  className="mt-1 h-6 w-6 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    A Simple Wish is All it Takes
                  </strong>{" "}
                  Looking mom creators who love home DIY? Just say the word!
                  Think of us as your personal genie. Your wish is our command.
                </span>
              </li>
              <li className="flex gap-x-3">
                <CheckBadgeIcon
                  className="mt-1 h-6 w-6 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Pure and Up-to-Date Data
                  </strong>{" "}
                  We keep things neat and tidy. Our creator data comes straight
                  from social media platforms, freshened up every week. No
                  third-party brokers, no outdated info, just the real deal.
                </span>
              </li>
              <li className="flex gap-x-3">
                <CheckBadgeIcon
                  className="mt-1 h-6 w-6 flex-none text-blue-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    Fair and Friendly Pricing
                  </strong>{" "}
                  We're a cozy team of tech wizards, not a big, fancy
                  corporation. We love what we do, and we're all about sharing
                  the joy without emptying your pockets.
                </span>
              </li>
            </ul>
            <p className="mt-8">
              Every brand has a unique flair, and we're here to match it.
              Whether you're a sprightly startup or a seasoned brand, Social
              Grab molds to fit your needs, just like your favorite pair of
              jeans. If you want to learn more about our brand offerings such as
              increased data access, unrestricted search limits, or custom API
              integrations lets{" "}
              <a
                className="underline"
                href="https://www.calendly.com/mattdvertola"
              >
                get in touch.
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
